import React, { useState } from 'react';
// import { navigate } from 'gatsby-link'
import { formEncode } from '../utils/utils'
import Popup from './popup';
import { Grid, TextField, Button} from '@material-ui/core';

function FormContact(props){
// console.log(props.translation);
// let translation = props.translation;
let [popUpVisible, setPopUpVisible] = useState(false);

const [formvalue, setFormvalue] = useState({
    fname: "",
    email: "",
    subject: "",
    message: "",
});


function handleChange(e){
    const newValue = e.target.value;
    const fieldName = e.target.name;
    const newFormValue = {...formvalue};
    newFormValue[fieldName] = newValue;
    // console.log(newFormValue);

    setFormvalue(newFormValue);
}

function handleSubmit(e){
    e.preventDefault();
    // console.warn(e.target.name, formvalue);
    
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: formEncode({
        'form-name': form.getAttribute('name'),
        ...formvalue,
      }),
    })
    .then(() => {
        setPopUpVisible(true);
    //   navigate(form.getAttribute('action'))
    })
    .catch((error) => {
        // console.error(error);
    });
}



return(
    <div className="bot-contact_in">
         {
          popUpVisible && <Popup buttonText="Close" noClose={true} onClose={()=>setPopUpVisible(false)}>{props.translation.formContact.messageSent}</Popup>
         }   
        <form method="post" autoComplete="off" name="contact" id="contact" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
            <Grid container spacing={2} className='bot-fields-container'>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="text" name="fname" title="Your full name" id="fname" label={props.translation.formContact.name} value={formvalue.fname} onChange={handleChange} required fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="email" name="email" title="Your email address" id="email" label={props.translation.formContact.email} value={formvalue.email} onChange={handleChange} required fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="text" name="subject" title="Message subject" id="subject" label={props.translation.formContact.subject} value={formvalue.subject} onChange={handleChange} required fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-ta" variant="filled" name="message" tittle="Message" id="message" rows="5" label={props.translation.formContact.message} value={formvalue.message} onChange={handleChange} multiline fullWidth></TextField>
                </Grid>
                <Grid item xs={12}><Button variant='contained' color='primary' type="submit">{props.translation.formContact.submit}</Button></Grid>
            </Grid>
        </form>
    </div>
    )
}



export default FormContact