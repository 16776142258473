import React, { useState } from 'react';
import tikka from "../../content/assets/img/tikka-bg.png";
import logo from "../../content/assets/img/logo.png";
import { Dialog, DialogContent } from '@material-ui/core';
import { Grid, Paper, TextField, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { formEncode } from '../utils/utils';

import "./contact-popup.css";

export const ContactPopupFrame = (props) => {
  const { translation, onClose, open } = props;
  const [formvalue, setFormvalue] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    subject: "Quick contact form submission",
    message: "",
  });


  function handleChange(e){
      const newValue = e.target.value;
      const fieldName = e.target.name;
      const newFormValue = {...formvalue};
      newFormValue[fieldName] = newValue;
      //console.log(newFormValue);

      setFormvalue(newFormValue);
  }

  function handleSubmit(e){
    e.preventDefault();
    //console.warn(e.target.name, formvalue);
    
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: formEncode({
        'form-name': form.getAttribute('name'),
        ...formvalue,
      }),
    })
    .then(() => {
        handleClose(true);
    //   navigate(form.getAttribute('action'))
    })
    .catch((error) => {
        // console.error(error);
    });
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog className="contact-popup-frame" maxWidth="md" open={open}>
      <DialogContent style={{height: "auto", padding: "25px" }}>
        <IconButton aria-label="close" onClick={handleClose} className="closeButton"><CloseIcon/></IconButton>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={6} className="grid-cell">
            <Paper className="detailSection grid-content">
              <img className="logo" alt="Logo black" src={logo} />
              <p>
                <span className="detailTitle">
                  <br/>
                  {translation.popupContactForm.message}
                  <br /><br />
                </span>
                <span className="detailText">
                  {translation.popupContactForm.description}<br />
                </span>
              </p>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Paper className="grid-content" elevation={0}>
              <img className="tikkaImage" alt="Tikka" src={tikka}/>
              <form method="post" autoComplete="off" name="contactPopup" id="contactPopup" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
              <input type="hidden" name="form-name" value="contactPopup" />
                <Grid container spacing={3} className="grid-cell">
                  <Grid item xs={12}><p className="contactTitle">{translation.popupContactForm.title}</p></Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField id="firstName" name="fname" label={translation.popupContactForm.firstName} onChange={handleChange} fullWidth/>
                    </Grid>
                  <Grid item xs={12} sm={6}><TextField id="lastName" name="lname" label={translation.popupContactForm.lastName} onChange={handleChange} fullWidth/></Grid>
                  <Grid item xs={12}><TextField id="phone" name="phone" type="tel" label={translation.popupContactForm.phone} onChange={handleChange} fullWidth/></Grid>
                  <Grid item xs={12}><TextField id="email" name="email" type="email" label={translation.popupContactForm.email} onChange={handleChange} fullWidth/></Grid>
                  <Grid item xs={12}><TextField id="message" name="message" label={translation.popupContactForm.help} onChange={handleChange} multiline fullWidth /></Grid>
                  <Grid item xs={12} container alignItems="flex-start" justifyContent="center">
                    <Button className="submitButton" color='primary' type="submit" variant="contained">{translation.popupContactForm.submit}</Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
