import React from "react"
import ReactMarkdown from "react-markdown"
import GaSection from "./gaSection";
import BackgroundImage from "gatsby-background-image";
import Carousel from 'react-material-ui-carousel'
import Img from "gatsby-image"

function Reference(props) {
    let refLinks = props.info.list;
    let refs = props.list;

    return (
        <GaSection name="References">
        <section className="bot-reference">
            <div className="bot-hero-pic">
                <BackgroundImage className="bot-hero-pic" fluid={props.info.image.childImageSharp.fluid}/>
            </div>
            <div className="bot-hero">
                <div id="references" className="bot-hero-tag"></div>
                <div className="bot-hero__in">
                    <div className=" bot-hero-title tac banner"  dangerouslySetInnerHTML={{__html: props.info.title}}></div>
                </div>
            </div>
            <div className="bot-section bg-gray">
                <div className="bot-section__in container-800">
                    <h1>{props.info.subtitle}</h1>
                    <div className="bot-item">
                        <ReactMarkdown source={props.info.intro} linkTarget="_blank"/>
                    </div>
                    <div className="bot-item bot-quote">
                            <ReactMarkdown source={props.info.quote} linkTarget="_blank"/>
                    </div>
                    <Carousel
                        interval="4000">
                        {
                            props.info.pictures.map(({picture}, i) => 
                                <Img key={`info-picture-${i}`} fluid={picture.childImageSharp.fluid} />
                            )
                        }
                    </Carousel>

                    <div className="bot-item">
                        <ReactMarkdown source={props.info.description} linkTarget="_blank"/>
                    </div>
                    <div className="bot-reference-logos df mb60">
                        {
                            refLinks.map((link, i) => {
                                let ref = refs.filter((r)=>r.fields.slug === "/"+link.reference+"/")[0];
                                return (
                                    <img key={i} alt="logo" src={ref.frontmatter.logo.publicURL} className="bot-reference-logo"></img>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    </GaSection>

    )
}
export default Reference