import React, { useRef, useState } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { kebabCase } from "lodash";

function GaSection(props){
    const ref = useRef(null);
    const [isDone, setDone] = useState(false);

    useScrollPosition(
        ({ currPos }) => {
            if (!isDone) {
                if ((currPos.y - (window.innerHeight || document.documentElement.clientHeight)) <= 0){
                    trackVisit(props.name);
                }
                // console.log(props.name + " done:", isDone, "pos: ", currPos, `top visible: ${(currPos.y - (window.innerHeight || document.documentElement.clientHeight)) <= 0}`);
            }
        },
        [isDone],
        ref,
        false,
        300
      );

    const trackVisit = (name) => {
        if (isDone) return;
        setDone(true);
        if (!sessionStorage.getItem(kebabCase(name)+'-visited')) {
            // console.log("Tracked visit of ", name);
            sessionStorage.setItem(kebabCase(name)+'-visited', true);
            trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Section: "+name,
                // string - required - Type of interaction (e.g. 'play')
                action: "visit",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                // label: "Gatsby Plugin Example Campaign",
                label: "Index page section views",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                // value: 43
            });
        }

      }

    return (
        <div ref={ref} className="bot-ga-section">
            { props.children }
        </div>
    );
}

export default GaSection