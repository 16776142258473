import React from "react"
import ReactMarkdown from "react-markdown"
import { kebabCase } from "lodash";
import GaSection from "./gaSection";
import BackgroundImage from "gatsby-background-image";

function Services(props) {
    let services = props.info;
    let contacts = props.contacts;
    // console.log(props.contacts);

    return (
        <GaSection name="Services">
            <section className="bot-services">
                <div className="bot-hero-pic">
                    <BackgroundImage className="bot-hero-pic" fluid={props.info.image.childImageSharp.fluid}/>
                </div>
                <div className="bot-hero">
                    <div id="services" className="bot-hero-tag"></div>
                    <div className="bot-hero__in">
                        <div className=" bot-hero-title tac banner"  dangerouslySetInnerHTML={{__html: props.info.title}}>
                            
                        </div>
                    </div>
                </div>
                <div className="bot-section bg-gray">
                    <div className="bot-section__in container-800">
                        <div className="bot-item">
                            <h1>{services.subtitle}</h1>
                            <ReactMarkdown source={services.description} linkTarget="_blank"/>
                        </div>
                        {
                            services.services.map((service) => {
                                let contact1 = contacts.filter((c)=>c.fields.slug === "/"+service.contact+"/")[0];
                                let expert = contacts.filter((c)=>c.fields.slug === "/"+service.expert+"/")[0];
                                return (
                                    <div key={service.title} className="bot-item">
                                        <div id={kebabCase(service.title)} className="bot-item-tag"></div>
                                        <h2 className="uc">{service.title}</h2>
                                        <ReactMarkdown source={service.description} linkTarget="_blank"/>
                                        <div className="bot-services-link">
                                            <p>{props.translation.services.moreInformation} {contact1.frontmatter.name}</p>
                                            <a href={"mailto:"+contact1.frontmatter.email}>{contact1.frontmatter.email}</a>, <a href={"tel:"+contact1.frontmatter.phone}> {contact1.frontmatter.phone}</a>
                                        </div>
                                        <p>{props.translation.services.ourExpert} {expert.frontmatter.name}, <a href={expert.frontmatter.linkedin}>LinkedIn</a></p>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </GaSection>

    )
}
export default Services